<eui-app [userInfos]="userInfos?.fullName" isSidebarOpen=true>
    <eui-app-toolbar>
        <eui-toolbar>
            <eui-toolbar-logo></eui-toolbar-logo>
            <eui-toolbar-app appName="appName"></eui-toolbar-app>
            <eui-toolbar-environment>MOCK</eui-toolbar-environment>
            <eui-toolbar-items euiPositionRight>
                <eui-toolbar-item-user-profile>
                    <eui-user-profile-menu>
                        <eui-user-profile-menu-item>
                            <eui-icon iconClass="eui-icon-person-thin"></eui-icon> {{ 'eui.my-profile-informations' | translate }}
                        </eui-user-profile-menu-item>
                        <eui-user-profile-menu-item>
                            <eui-icon iconClass="eui-icon-logout-thin"></eui-icon> {{ 'eui.sign-out' | translate }}
                        </eui-user-profile-menu-item>
                    </eui-user-profile-menu>
                </eui-toolbar-item-user-profile>
                <eui-toolbar-item-notifications>
                    <eui-notifications [count]="notificationItems?.length" [items]="notificationItems"></eui-notifications>
                </eui-toolbar-item-notifications>
            </eui-toolbar-items>
        </eui-toolbar>
    </eui-app-toolbar>
    <eui-app-sidebar>
        <eui-app-sidebar-body>
            <eui-app-sidebar-menu [items]="sidebarItems"></eui-app-sidebar-menu>
        </eui-app-sidebar-body>
    </eui-app-sidebar>
</eui-app>
